import { observable, decorate } from 'mobx';

class Global {
  constructor() {
    this.lang = this.loadStoredLanguage();
  }

  loadStoredLanguage = () => {
    let languageToLoad = 'en';
    const possibleLanguages = ['es', 'en', 'fr', 'de', 'it', 'nl'];
    const localStorageResult = localStorage.getItem('_site_lang');

    if (localStorageResult && possibleLanguages.includes(localStorageResult.toLocaleLowerCase())) {
      languageToLoad = localStorageResult.toLowerCase();
    }

    return languageToLoad;
  };

  updateLanguage = (lang) => {
    this.lang = lang;
    if (typeof Storage !== 'undefined') {
      localStorage.setItem('_site_lang', lang.toLowerCase());
    }
  };
}

decorate(Global, {
  lang: observable
})

export default new Global();
