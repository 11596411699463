import React, {useState} from 'react';
import { useObserver } from 'mobx-react-lite';
import { useStores } from 'dpt-react/hooks';

import styles from '../../assets/styles/header.module.scss'

const Header = () => {
  const { global } = useStores();
  const [isOpen, setState ] = useState(false);

  const languages = [
    {short: 'nl', long: 'Nederlands'},
    {short: 'fr', long: 'Français'},
    {short: 'de', long: 'Deutsch'},
    {short: 'en', long: 'English'},
    {short: 'es', long: 'Español'},
    {short: 'it', long: 'Italiano'},
  ];

  if (document.location.href.includes('sso.dev') || document.location.href.includes('local')) {
    languages.push({short: 'trans', long: 'translations'});
  }


  const getLanguage = lang => {
    return languages.find(language => language.short === lang)?.long;
  };


  const changeLanguage = ev => {
    const lang = ev.target.getAttribute('data-id');
    global.updateLanguage(lang.toLowerCase());
  };

  return useObserver(() => { 
    const { lang } = global;

    const LanguageDropdown = () => (
      isOpen && (
        <>
          <div className={styles.overlay} onClick={() => setState(false)}/>
          <ul className={styles.languageDropdown}>
            {
              languages.map(language => {
                return (
                  <li data-id={language.short} onClick={changeLanguage} key={language.short}>
                    {language.long}
                  </li>
                )
              })
            }
          </ul>  
        </>
      )
    )

    return (
      <header className={styles.header}>
        <span onClick={() => setState(!isOpen)} className={styles.languageTrigger}>
          <span className={styles.lang}>
            {getLanguage(lang)}
            <i className="fas fa-chevron-down" />
          </span>
          <LanguageDropdown />
        </span>
      </header>
    )
  });
};

export default Header;
