export default {
  "footer_company": "DBMatic",
  "title": "DBMatic IoT Platform",
  "header_logo": "dbmatic_logo_header.svg",
  "sidenav_logo": "dbmatic_sidenav_logo.svg",
  "sidenav_text": "<h1> <span>Connect.</span> <strong>Anything.</strong> <span>Anywhere.</span> </h1>",
  "company": "BlueCherry",
  "use_proxy": true,
  "show_footer_logo": true
}


